<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          My Tests
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.grade"
          placeholder="Grade"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.gradeList"
            :key="item.name"
            :label="this.$formatter.formatGrade(item.description)"
            :value="item.name"
          ></el-option>
        </el-select>
        <el-select v-model="query.term" placeholder="Term" class="mr10" :clearable="true">
          <el-option
            v-for="item in options.termList"
            :key="item.name"
            :label="item.description"
            :value="item.name"
          ></el-option>
        </el-select>
      </div>
      <el-table
        :data="filterData()"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="testReport.name" label="Report Name"></el-table-column>
        <el-table-column
          prop="testReport.grade"
          label="Grade"
          :width="this.$widthRatio * 100 + 'px'"
          :formatter="$tableCellFormatter.formatGrade"
        ></el-table-column>
        <el-table-column
          prop="testReport.term"
          label="Term"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="foundation"
          label="Foundation"
          :width="this.$widthRatio * 90 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="development"
          label="Development"
          :width="this.$widthRatio * 95 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="enrichment"
          label="Enrichment"
          :width="this.$widthRatio * 90 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="total"
          label="Total"
          :width="this.$widthRatio * 80 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          label="Published Time"
          :width="this.$widthRatio * 110 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          :width="this.$widthRatio * 160 + 'px'"
          align="center"
        >
          <template #default="scope">
            <el-button type="text" @click="showTestReport(scope.$index, scope.row)">
              <el-icon><histogram /></el-icon>
              Report
            </el-button>
            <el-popover
              v-if="(scope.row.comment || '').length > 0 ? true : false"
              placement="bottom"
              title="Your teacher's comment"
              ::width="this.$widthRatio * 200 + 'px'"
              trigger="click"
              :content="scope.row.comment"
            >
              <template #reference>
                <el-button type="text">
                  <el-icon><document /></el-icon>
                  Comments
                </el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        ref="testRecordReportDialog"
        :title="testRecordReportDialogTitle"
        v-model="showTestRecordReportDialog"
        width="90%"
      >
        <test-report-chart
          :isForAdmin="false"
          :reportId="currentReport.reportId"
          :studentId="query.studentId"
          :destroy-on-close="true"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";
import TestReportChart from "../common/TestReportChart.vue";

export default {
  components: { TestReportChart },
  name: "testReportList",
  data() {
    return {
      controllerUrl: "/testReport",
      query: {
        studentId: null,
        reportId: null,
        grade: null,
        term: null,
        orderBy: "UpdatedTime",
        orderDirection: "ASC",
      },
      options: {
        gradeList: [],
        termList: [],
      },
      tableData: [],
      form: {},
      dialogTitle: "",
      currentReport: null,
      showTestRecordReportDialog: false,
      testRecordReportDialogTitle: "",
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;

    let studentId = this.$user.getUsername();
    console.log("StudentProfile.created", studentId);
    if (studentId) {
      this.query.studentId = studentId;
      this.loadData();
    }
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/getCurrentStudentTestRecords`).then((res) => {
        if (res.result && res.code === "200") {
          this.tableData = res.result;
        }
      });
    },
    filterData() {
      let recordList = this.tableData;
      if (this.query.grade) {
        recordList = recordList.filter((e) => e.testReport.grade === this.query.grade);
      }
      if (this.query.term) {
        recordList = recordList.filter((e) => e.testReport.term === this.query.term);
      }
      return recordList;
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
    },
    showTestReport(index, row) {
      this.currentReport = row.testReport;
      this.testRecordReportDialogTitle = `My report for the test records '${this.currentReport.name}'`;
      this.showTestRecordReportDialog = true;
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
